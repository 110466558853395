@charset "utf-8";
/* HIVELAB (http://www.hivelab.co.kr) */
@media all and (max-width: 768px) {
  /* device common */
  .is_pc_show,
  .is_pc_ibshow {
    display: none !important;
  }

  .is_m_show {
    display: block !important;
  }

  .is_m_ibshow {
    display: inline-block !important;
  }

  /* GNB */
  #toast_header .btn_gnb_close:before,
  #toast_header .gnb .service_title:after,
  #toast_header .gnb .lst_gnb .link_menu:after,
  #toast_header .btn_gnb_logout:before,
  #toast_header .btn_gnb:before,
  .sitemap_wrap .lst_sitemap .title_txt:after,
  #toast_header .gnb .lst_gnb .ico_link {
    display: inline-block;
    overflow: hidden;
    background-image: url(https://static.toastoven.net/toast/resources/css/../img/sp_gnb_m.png) , url(https://www.nhncloud.com/resources/img/../img/sp_gnb_m.png) ;
    background-repeat: no-repeat;
    background-size: 250px auto;
    font-size: 10px;
    line-height: 999px;
  }

  /* header */
  #toast_header {
    min-width: auto;
    background-color: #fff;
    -webkit-box-shadow: none;
            box-shadow: none;
  }

  #toast_header .inner {
    position: relative;
    display: block;
    min-width: auto;
    padding: 0 16px;
  }

  #toast_header .logo {
    height: 56px;
  }

  #toast_header .logo .link_txt img {
    width: 85px;
    height: 28px;
  }

  #toast_header .logo .link_txt img.is_white {
    display: none;
  }

  #toast_header .logo .logo_img {
    display: none;
  }

  #toast_header .logo .logo_img_m {
    display: block;
  }

  #toast_header .logo .logo_img_m.ngsc{
    width:236px;
    height:28px
  }

  #toast_header .gnb {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
  }

  #toast_header .gnb .gnb_scroll_wrap {
    overflow: auto;
    margin-right: 0;
  }

  #toast_header .gnb .lst_gnb {
    display: block;
    border-top: 1px solid #ddd;
    background-color: #fff;
  }

  #toast_header .gnb .lst_gnb .gnb_menu {
    display: block;
    padding: 0;
  }

  #toast_header .gnb .lst_gnb .link_menu {
    display: block;
    z-index: 0;
    padding: 0;
    border-bottom: 1px solid #eee;
  }

  #toast_header .gnb .lst_gnb .menu_txt {
    display: block;
    padding: 0;
    font-size: 16px;
    line-height: 55px;
    font-weight: 500;
    color: #333;
  }

  #toast_header .gnb .lst_gnb .link_menu:after,
  #toast_header .gnb .service_title:after {
    position: absolute;
    top: 50%;
    right: 22px;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    background-position: 0 -19px;
  }

  #toast_header .gnb .lst_gnb .link_menu[data-toggle]:after,
  #toast_header .gnb .service_title[data-toggle]:after {
    display: inline-block;
    content: '';
  }

  #toast_header .gnb .lst_gnb .link_menu[aria-expanded='true']:after,
  #toast_header .gnb .service_title[aria-expanded='true']:after {
    background-position: -14px -19px;
  }

  #toast_header .gnb .lst_gnb .link_menu.collapsed:after {
    background-position: 0 -19px;
  }

  #toast_header .gnb .lst_gnb .link_menu:after {
    right: 22px;
    left: auto;
    bottom: auto;
    height: 12px;
    background-color: transparent;
    background-position: 0 -19px;
    -webkit-animation: unset;
            animation: unset;
  }

  #toast_header .gnb .lst_gnb .link_menu .menu_txt {
    padding: 0 16px;
    color: #333;
  }

  #toast_header .gnb .lst_gnb .link_menu[aria-expanded='true'] .menu_txt,
  #toast_header .gnb .service_title[aria-expanded='true'] {
    color: #125de6;
  }

  #toast_header .gnb .lst_gnb .link_menu.is_selected .menu_txt:after {
    display: none;
  }

  #toast_header .gnb .lst_gnb .gnb_menu.is_point {
    margin-left: 0;
    background-color: #125de6;
    line-height: 30px;
  }

  #toast_header .gnb .lst_gnb .gnb_menu.is_point .link_menu {
    display: inline-block;
    height: 56px;
    margin-top: 0;
    border-bottom: 0;
    border-radius: 0;
  }

  #toast_header .gnb .lst_gnb .gnb_menu.is_point .link_menu.is_hover {
    background-color: #125de6;
  }

  #toast_header .gnb .lst_gnb .gnb_menu.is_point .link_menu .menu_txt {
    color: #fff;
    line-height: 56px;
  }

  #toast_header .gnb .lst_gnb .ico_link {
    width: 14px;
    height: 14px;
    margin-left: 10px;
    background-position: -42px -19px;
    vertical-align: middle;
  }

  #toast_header .gnb .lst_gnb .gnb_menu.is_point .ico_link {
    width: 14px;
    height: 14px;
    margin-left: 10px;
    background-position: -56px -19px;
    vertical-align: middle;
  }

  /* drop menu */
  #toast_header .gnb .link_menu + .drop_menu_wrap {
    display: block;
    visibility: visible;
    overflow: hidden;
    position: relative;
    top: auto;
    width: auto;
    height: 0;
    padding: 0;
    border: 0;
    opacity: 1;
    -webkit-transition: height 0.35s ease;
    transition: height 0.35s ease;
  }

  #toast_header .gnb .link_menu + .drop_menu_wrap.show {
    height: auto;
    padding-left: 0;
    border-bottom: 1px solid #eee;
  }

  #toast_header .gnb .drop_menu_wrap .menu_txt {
    display: block;
    padding: 0 40px;
    line-height: 48px;
    font-size: 15px;
    font-weight: 400;
    color: #222;
  }

  #toast_header .drop_menu_wrap .lst_gnb_sub {
    background-color: #fafafa;
  }

  #toast_header .drop_menu_wrap .lst_gnb_sub .gnb_sub_menu {
    display: block;
  }

  #toast_header .gnb .service_drop_menu {
    width: auto;
  }

  #toast_header .gnb .service_drop_menu .scroll_wrap {
    overflow: hidden;
    max-height: unset;
    margin: 0;
  }

  #toast_header .gnb .service_drop_menu .row_wrap {
    display: block;
    min-width: auto;
    width: auto;
    margin: 0;
    padding: 0;
    border-top: 0;
  }

  #toast_header .gnb .col_group {
    display: block;
    float: none;
    margin-right: 0;
  }

  #toast_header .gnb .service_drop_menu .row_wrap.width_fixed {
    overflow: initial;
  }

  #toast_header .gnb .service_drop_menu .row_wrap.width_fixed .col_group {
    float: none;
    width: auto;
  }

  #toast_header .gnb .service_title {
    position: relative;
    width: auto;
    margin: 0;
    padding: 0 40px;
    border-top: 0;
    background-color: #fafafa;
    line-height: 48px;
    font-weight: normal;
  }

  #toast_header .gnb .lst_service_menu + .service_title:before {
    content: none;
  }

  #toast_header .gnb .lst_service_menu {
    width: auto;
    margin: 0;
    background: ; background-color: #f1f1f1;
  }

  #toast_header .gnb .lst_service_menu.collapse:not(.show) {
    display: none;
  }

  #toast_header .gnb .lst_service_menu.collapse.show {
    display: block;
  }

  #toast_header .gnb .lst_service_menu .service_menu + .service_menu {
    margin: 0;
  }

  #toast_header .gnb .lst_service_menu .service_menu + .service_menu {
    margin: 0;
  }

  #toast_header .gnb .lst_service_menu .link_txt {
    display: block;
    width: auto;
    padding: 13px 64px;
    line-height: 22px;
    font-size: 15px;
    white-space: normal;
    word-break: break-word;
  }

  #toast_header .gnb .lst_service_menu .link_txt .service_txt {
    margin-right: 5px;
  }

  #toast_header .gnb .lst_service_menu .is_new .link_txt .service_badge:after, #toast_header .gnb .lst_service_menu .is_update .link_txt .service_badge:after {
    position: static;
    display: inline-block;
    margin: 5px 0 0 0;
    vertical-align: top;
  }

  #toast_header .side_menu_area {
    display: block;
    float: none;
    position: absolute;
    right: 56px;
    top: 0;
    bottom: 0;
    max-width: unset;
    margin: 0;
    text-align: right
  }

  #toast_header .side_menu_area .search_word_area {
    display: none;
  }

  #toast_header .side_menu_area .lang_select_area {
    top: 50%;
    margin-top: -15px;
    margin-left: 0
  }

  #toast_header .side_menu_area .lang_select_area .btn_select_lang {
    width: 28px;
    height: 28px;
  }

  #toast_header .side_menu_area .lang_select_area .lst_lang {
    top: 39px;
    left: -23px;
    right: -23px;
    padding: 0;
    border-color: #ddd;
  }

  #toast_header .side_menu_area .lang_select_area .lst_lang.is_show {
    -webkit-transition: none;
    transition: none;
  }

  #toast_header .side_menu_area .lang_select_area .btn_lang {
    font-size: 16px;
    line-height: 48px;
    font-weight: 400;
  }

  #toast_header .side_menu_area .lang_select_area .lang_item .btn_lang:hover {
    background-color: transparent;
  }

  #toast_header .side_menu_area .lst_member_link {
    display: none
  }

  #toast_header .side_menu_area .link_newsletter {
    position: relative;
    top: 50%;
    right: 0;
    margin-top: -15px;
    margin-left: 16px;
    padding: 5px 15px;
    height: 30px;
    border-color: #125de6;
    color: #125de6;
    line-height: 18px
  }

  #toast_header .side_menu_area .link_newsletter:focus {
    color: #125de6
  }

  #toast_header.is_fixed .link_newsletter:hover,
  #toast_header.is_hover .side_menu_area .link_newsletter:hover,
  #toast_header.is_fixed .side_menu_area .link_newsletter:focus,
  #toast_header.is_hover .side_menu_area .link_newsletter:focus {
    border-color: #125de6;
    color: #125de6
  }

  #toast_header.is_fixed .side_menu_area .link_newsletter:hover,
  #toast_header.is_hover .side_menu_area .link_newsletter:hover,
  #toast_header.is_fixed .side_menu_area .link_newsletter:focus,
  #toast_header.is_hover .side_menu_area .link_newsletter:focus {
    border-color: #0042c6;
    color: #0042c6
  }

  /* gnb_open */
  #toast_header .btn_gnb,
  #toast_header .btn_gnb_close {
    position: absolute;
    top: 50%;
    right: 16px;
    width: 24px;
    height: 24px;
    margin-top: -12px;
  }

  #toast_header .btn_gnb_close {
    top: 0;
    margin-top: 16px;
  }

  #toast_header .btn_gnb:before,
  #toast_header .btn_gnb_close:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 16px;
    margin: -8px 0 0 -9px;
    content: '';
  }

  #toast_header .btn_gnb_close:before {
    background-position: -20px 0;
  }

  #toast_header .btn_gnb_logout {
    position: absolute;
    top: 50%;
    right: 16px;
    width: 24px;
    height: 24px;
    margin-top: -12px;
  }

  #toast_header .btn_gnb_logout:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 17px;
    height: 17px;
    margin: -8px 0 0 -8px;
    background-position: -40px 0;
    content: '';
  }

  /* login */
  #toast_header .top_wrap {
    display: block;
    position: relative;
    z-index: 1;
    min-height: 56px;
    padding: 10px 16px 9px;
    background-color: #fff;
  }

  #toast_header .top_wrap .member_id {
    display: block;
    margin-top: 5px;
    margin-bottom: 13px;
    font-size: 16px;
    color: #333;
    font-weight: 400;
  }

  #toast_header .top_wrap .btn_login,
  #toast_header .top_wrap .btn_logout {
    display: inline-block;
    padding: 0 14px 0 12px;
    border: 1px solid #125de6;
    font-size: 16px;
    color: #125de6;
    vertical-align: middle;
    font-weight: 500;
    line-height: 34px;
  }

  #toast_header .top_wrap .btn_logout {
    margin-bottom: 7px;
  }

  #toast_header .gnb .top_wrap.is_login {
    display: none;
  }

  #toast_header .gnb .top_wrap.is_non_login {
    display: block;
  }

  #toast_header .gnb.is_member .top_wrap.is_login {
    display: block;
  }

  #toast_header .gnb.is_member .top_wrap.is_non_login {
    display: none;
  }

  /* GNB open */
  body.is_show_mgnb .wrap_outer {
    overflow: hidden;
    height: 100%;
    padding-top: 0;
  }

  body.is_show_mgnb #toast_header {
    position: relative;
  }

  body.is_show_mgnb #toast_header .gnb {
    display: block;
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    background-color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
  }

  body.is_show_mgnb #toast_header .gnb::-webkit-scrollbar {
    display: none;
  }

  /* footer */
  #toast_footer {
    min-width: auto;
  }

  #toast_footer .sitemap_wrap .logo_toast img {
    width: 85px;
    height: auto;
  }

  #toast_footer .sitemap_wrap .logo_toast.ngsc {
    display:block;margin-bottom:10px;
  }
  #toast_footer .sitemap_wrap .logo_toast img.ngsc{
    width:236px;height:28px;
  }
  #toast_footer .sitemap_wrap .logo_toast.ngsc + .lst_sns{
    margin-left:0;
  }

  #toast_footer .sitemap_wrap .lst_sns .link_sns {
    background-image: url(https://static.toastoven.net/toast/resources/css/../img/sp_gnb_m.png) , url(https://www.nhncloud.com/resources/img/../img/sp_gnb_m.png) ;
    background-size: 250px auto;
  }

  #toast_footer .sitemap_wrap .lst_sns .ico_twitter {
    background-position: -32px -33px;
  }

  #toast_footer .sitemap_wrap .lst_sns .ico_facebook {
    background-position: 0 -33px;
  }

  #toast_footer .sitemap_wrap .lst_sns .ico_youtube {
    background-position: -64px -33px;
  }

  #toast_footer .sitemap_wrap .lst_sns .ico_blog {
    background-position: -96px -33px;
  }

  #toast_footer .sitemap_wrap .lst_sitemap .col_group {
    display: block;
    min-width: auto;
    width: auto;
  }

  #toast_footer .sitemap_wrap .lst_sitemap .col_group + .col_group {
    margin-left: 0;
  }

  #toast_footer .sitemap_wrap .lst_sitemap .is_col_merge2 .col_group {
    width: auto;
  }

  #toast_footer .sitemap_wrap .lst_sitemap .col_group.is_col_merge2 {
    width: auto;
    margin-left: 0;
  }

  #toast_footer .sitemap_wrap .inner {
    padding: 26px 16px 0;
  }

  #toast_footer .sitemap_wrap .lst_sns {
    margin-left: 16px;
  }

  #toast_footer .sitemap_wrap .lst_sns .item_sns {
    margin-left: 8px;
  }

  #toast_footer .sitemap_wrap .lst_sitemap {
    margin: 23px -16px 0;
    border-top: rgba(255, 255, 255, 0.08) solid 1px;
  }

  #toast_footer .sitemap_wrap .lst_sitemap .title_txt {
    display: block;
    position: relative;
    height: 56px;
    padding: 0 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    line-height: 54px;
    font-size: 16px;
  }

  #toast_footer .sitemap_wrap .lst_sitemap .title_point:after {
    display: none;
  }

  #toast_footer .sitemap_wrap .lst_sitemap .title_txt:after {
    position: absolute;
    top: 50%;
    right: 22px;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    background-position: -28px -19px;
    content: '';
  }

  #toast_footer .sitemap_wrap .lst_sitemap .title_txt[aria-expanded='true'] {
    color: #4281f3;
  }

  #toast_footer .sitemap_wrap .lst_sitemap .title_txt[aria-expanded='true']:after {
    background-position: -70px -19px;
  }

  #toast_footer .sitemap_wrap .lst_sitemap .title_point {
    color: #4281f3;
  }

  #toast_footer .sitemap_wrap .lst_sitemap .lst_sitemap_detail {
    display: block;
    margin-top: 0;
    background-color: #2b2b2b;
  }

  #toast_footer .sitemap_wrap .lst_sitemap .title_txt + .lst_sitemap_detail {
    margin-top: 0;
  }

  #toast_footer .sitemap_wrap .lst_sitemap .title_txt + .lst_sitemap_detail.show {
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  }

  #toast_footer .sitemap_wrap .lst_sitemap .lst_sitemap_detail + .title_txt {
    margin-top: 0;
  }

  #toast_footer .sitemap_wrap .lst_sitemap .lst_sitemap_detail:not(.show) {
    display: none;
    height: 0;
  }

  #toast_footer .sitemap_wrap .lst_sitemap .lst_sitemap_detail.collapsing {
    display: block;
    height: 0;
    -webkit-transition: height 0.35s ease;
    transition: height 0.35s ease;
  }

  #toast_footer .sitemap_wrap .lst_sitemap .link_item {
    margin-top: 0;
  }

  #toast_footer .sitemap_wrap .lst_sitemap .link_txt {
    display: block;
    max-width: none;
    padding: 0 40px;
    line-height: 48px;
    font-size: 15px;
    color: #fff;
  }

  #toast_footer .nhn_footer .family_site_wrap {
    display: none;
  }

  #toast_footer .nhn_footer .lst_company_info {
    display: none;
  }

  #toast_footer .nhn_footer .lst_exemption_notice {
    display: none;
  }

  #toast_footer .nhn_footer {
    border-top: 0;
  }

  #toast_footer .nhn_footer .inner {
    padding: 20px 0 62px;
    text-align: center;
  }

  #toast_footer .nhn_footer .logo_nhn {
    top: auto;
    left: 50%;
    bottom: 34px;
    width: 77px;
    height: 24px;
    margin-left: -25px;
    background-size: contain
  }

  #toast_footer .logo_nhn img {
    width: 100%;
  }

  #toast_footer .nhn_footer .notice_txt {
    margin: 0 0 20px;
    padding: 0 16px;
    font-size: 10px;
    line-height: 14px;
    text-align: left;
  }

  #toast_footer .nhn_footer .lst_company_link {
    margin-left: 0;
  }

  #toast_footer .nhn_footer .lst_company_link .link_txt {
    padding: 0 9px;
    font-size: 10px;
  }

  #toast_footer .nhn_footer .lst_company_link .item_link:before {
    height: 10px;
    margin-top: -5px;
  }

  #toast_footer .nhn_footer .copyright_txt {
    margin-top: 2px;
    font-size: 10px;
  }
}
